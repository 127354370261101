import { useTheme } from '@emotion/react';
import { useRouter } from 'next/router';
import React, { useCallback } from 'react';

import { Button, Grid, Text } from '~/components/blocks';
import { Notice } from '~/components/layouts';

const _404Page = () => {
  const theme = useTheme();
  const router = useRouter();
  const handleClick = useCallback(() => {
    router.push('/');
  }, [router]);

  return (
    <Notice>
      <h2>ご指定のページは見つかりませんでした</h2>
      <Text mt={theme.space.l}>
        お探しのページは一時的にアクセスできない状況にあるか、移動もしくは削除された可能性があります。
      </Text>
      <Grid pt={theme.space.xxl}>
        <Button use="base" size="l" align="center" onClick={handleClick}>
          トップへ戻る
        </Button>
      </Grid>
    </Notice>
  );
};

export default _404Page;
